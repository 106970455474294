import { useTranslation } from 'react-i18next';
import { Container, Text, Link } from './styles';

export const Footer = ((): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container>
      <Text>
        {t('footer_msg')} <Link
          href={'https://github.com/FernasG'}
          target={'_black'}
        >
          Fernas
        </Link>
      </Text>
    </Container>
  );
});