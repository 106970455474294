const en = {
  translation: {
    profile_web_dev: 'Web Developer',
    about_title: 'About Me',
    about_paragraph: 'Hello! Welcome, I\'m a {{age}}-year-old Web Developer, studying Information Systems at the Federal University of Itajubá (UNIFEI). Currently, I work as a Junior Backend Developer at Zenvia. I\'m focused on improving my skills, studying Clean Code and Clean Architecture, AI, and Rust. I believe in the combination of my academic background and practical experience to contribute significantly to the development of innovative solutions.',
    exp_title: 'Experiences',
    exp_5zs_role: 'Front-end Developer',
    exp_5zs_period: 'May 2020 - Dec 2020',
    exp_obj_role: 'Full Stack Developer',
    exp_obj_period: 'May 2020 - Mar 2021',
    exp_zen_role: 'Back-end Developer',
    exp_zen_period: 'Apr 2021 - Present',
    tech_title: 'Technologies',
    tech_level: 'Level',
    tech_beginner: 'Beginner',
    tech_intermediate: 'Intermediate',
    tech_advanced: 'Advanced',
    tech_all: 'All',
    proj_title: 'Projects',
    proj_editor_nestjs_desc: 'Backend of the online code editor written in NestJS, using the HackerEarth API.',
    proj_editor_nextjs_desc: 'Frontend of the online code editor written in NextJS (React).',
    proj_dealership_desc: 'MVC of a vehicle dealership using NodeJS (Express) and Handlebars to generate the views.',
    proj_bz_gateway_desc: 'Blue Zone application that receives requests and redirects to other microservices using RabbitMQ.',
    proj_bz_system_desc: 'Blue Zone application that receives requests from the Gateway and performs system operations.',
    footer_msg: 'Developed with 💙 by',
    portuguese: 'Portuguese',
    spanish: 'Spanish',
    english: 'English'
  }
};

export default en;