const ptBR = {
  translation: {
    profile_web_dev: 'Desenvolvedor Web',
    about_title: 'Sobre Mim',
    about_paragraph: 'Olá! Seja bem vindo, sou Desenvolvedor Web de {{age}} anos, estudante de Sistemas da Informação na Universidade Federal de Itajubá (UNIFEI). Atualmente, trabalho como Desenvolvedor Backend Junior na empresa Zenvia. Estou focado em aprimorar minhas habilidades, estudando Clean Code e Clean Architecture, IA e Rust. Acredito na combinação entre minha formação acadêmica e experiência prática para contribuir de maneira significativa no desenvolvimento de soluções inovadoras.',
    exp_title: 'Experiências',
    exp_5zs_role: 'Desenvolvedor Front-end',
    exp_5zs_period: 'Mai de 2020 - Dez de 2020',
    exp_obj_role: 'Desenvolvedor Full Stack',
    exp_obj_period: 'Mai de 2020 - Mar de 2021',
    exp_zen_role: 'Desenvolvedor Back-end',
    exp_zen_period: 'Abr de 2021 - Atualmente',
    tech_title: 'Tecnologias',
    tech_level: 'Nível',
    tech_beginner: 'Iniciante',
    tech_intermediate: 'Intermediário',
    tech_advanced: 'Avançado',
    tech_all: 'Todos',
    proj_title: 'Projetos',
    proj_editor_nestjs_desc: 'Backend do editor de código online escrito em NestJS, utilizando a API da HackerEarth.',
    proj_editor_nextjs_desc: 'Frontend editor de código online escrito em NextJS (React).',
    proj_dealership_desc: 'MVC de loja de veículos utlizando NodeJS (Express) e Handlebars para gerar as views.',
    proj_bz_gateway_desc: 'Aplicação de Zona Azul que recebe requisições e redireciona para outrosmicroserviços com RabbitMQ.',
    proj_bz_system_desc: 'Aplicação de Zona Azul que recebe requisições do Gateway e executa operações de sistema.',
    footer_msg: 'Desenvolvido com 💙 pelo',
    portuguese: 'Português',
    spanish: 'Espanhol',
    english: 'Inglês'
  }
};

export default ptBR;