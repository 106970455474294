import styled from 'styled-components';

interface Props {
  width?: string;
  height?: string;
}

const Div = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled(Div)`
  width: 100%;
  flex-direction: column;
  row-gap: 15px;
`;

export const LanguageArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Header = styled(Div)`
  width: 100%;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
`;

export const Image = styled.img<Props>`
  border-radius: 50%;
  height: ${({ height }) => height ? height : '150px'};
  width: ${({ width }) => width ? width : '150px'};
  padding: 5px;
  border: 5px solid var(--medium-slate-blue);
`;

export const Title = styled(Div)`
  flex-direction: column;
  align-items: center;
`;

export const Headline1 = styled.h1``;

export const Headline2 = styled.h2`
  color: var(--gray2);
`;

export const Socials = styled(Div)`
  column-gap: 30px;
`;

export const Link = styled.a`
  color: var(--gray2);
  transition: all 500ms;
 
  &:hover {
    color: var(--medium-slate-blue);
  }
`;