import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const Title = styled.h3`
  color: var(--thistle);
`;

export const Panel = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
`;

export const Box = styled.div`
  display: inline-block;
  user-select: none;
  margin-right: 20px;
  
  &:last-child {
    margin-right: 0px;
  }
`;

export const BoxContent = styled.div`
  width: 250px;
  height: 200px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  white-space: break-spaces;
  background: var(--layer);
  cursor: pointer;
  padding: 10px;
`;

export const BoxHeader = styled.div`
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BoxTitle = styled.h2`
  font-size: 1em;
`;

export const BoxLink = styled.a`
  cursor: pointer;
  transition: all 500ms;

  &:hover {
    transform: scale(1.02);
    color: var(--thistle);
  }
`;

export const BoxBody = styled.div`
  width: 100%;
  height: 85%;
`;

export const Description = styled.p`
  font-size: 0.9em;
  color: var(--gray2);
  text-align: justify;
  display: block;
  height: 65%;
`;

export const IconsArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  height: 35%;
`;

export const Icon = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  background: var(--jet);
  display: flex;
`;