const es = {
  translation: {
    profile_web_dev: 'Desarrollador Web',
    about_title: 'Sobre mí',
    about_paragraph: '¡Hola! Bienvenido, soy Desarrollador Web de {{age}} años, estudiante de Sistemas de Información en la Universidad Federal de Itajubá (UNIFEI). Actualmente, trabajo como Desarrollador Backend Junior en la empresa Zenvia. Estoy enfocado en mejorar mis habilidades, estudiando Clean Code y Clean Architecture, IA y Rust. Creo en la combinación de mi formación académica y experiencia práctica para contribuir de manera significativa en el desarrollo de soluciones innovadoras.',
    exp_title: 'Experiencias',
    exp_5zs_role: 'Desarrollador Front-end',
    exp_5zs_period: 'Mayo de 2020 - Dic de 2020',
    exp_obj_role: 'Desarrollador Full Stack',
    exp_obj_period: 'Mayo de 2020 - Mar de 2021',
    exp_zen_role: 'Desarrollador Back-end',
    exp_zen_period: 'Abr de 2021 - Actualidad',
    tech_title: 'Tecnologías',
    tech_level: 'Nivel',
    tech_beginner: 'Principiante',
    tech_intermediate: 'Intermedio',
    tech_advanced: 'Avanzado',
    tech_all: 'Todos',
    proj_title: 'Proyectos',
    proj_editor_nestjs_desc: 'Backend del editor de código en línea escrito en NestJS, utilizando la API de HackerEarth.',
    proj_editor_nextjs_desc: 'Frontend del editor de código en línea escrito en NextJS (React).',
    proj_dealership_desc: 'MVC de tienda de vehículos utilizando NodeJS (Express) y Handlebars para generar las vistas.',
    proj_bz_gateway_desc: 'Aplicación de Zona Azul que recibe solicitudes y redirige a otros microservicios con RabbitMQ.',
    proj_bz_system_desc: 'Aplicación de Zona Azul que recibe solicitudes del Gateway y ejecuta operaciones de sistema.',
    footer_msg: 'Desarrollado con 💙 por',
    portuguese: 'Portugués',
    spanish: 'Español',
    english: 'Inglés'
  }
};

export default es;