import styled from 'styled-components';

interface ImageProps {
  width: string;
}

interface ParagraphProps {
  textAlign?: string;
  fontSize?: string;
  color?: string;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const Title = styled.h3`
  color: var(--teal);
`;

export const Panel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media only screen and (max-width: 1024px) {
    row-gap: 10px;
    align-items: center;
    flex-direction: column;
  }
`;

export const Card = styled.div`
  width: 250px;
  cursor: pointer;
  transition: all 500ms;
  background: var(--layer);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  &:hover {
    transform: scale(105%);
  }

  @media only screen and (max-width: 1024px) {
    width: 300px;
  }
`;

export const CardHeader = styled.div`
  width: 100%;
`;

export const CardTitle = styled.h2`
  width: 100%;
  font-size: 1em;
  text-align: center;
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const CardPicture = styled.picture`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CardImage = styled.img<ImageProps>`
  width: ${({ width }) => width};
  border-radius: 50%;
  padding: 4px;
  border: 3px solid var(--teal);
`;

export const CardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

export const Paragraph = styled.p<ParagraphProps>`
  width: 100%;
  text-align: ${({ textAlign }) => textAlign ? textAlign : 'center'};
  font-size: ${({ fontSize }) => fontSize ? fontSize : '0.9em'};
  color: ${({ color }) => color ? `var(--${color})` : 'var(--text-color)'};
`;