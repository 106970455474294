import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ptBR from './pt-BR';
import en from './en';
import es from './es';

const i18nSetup = i18n
  .use(initReactI18next)
  .init({
    resources: {
      'pt-BR': ptBR,
      'en-US': en,
      es: es,
      en: en
    },
    lng: navigator.language,
    fallbackLng: 'pt-BR',
    interpolation: {
      escapeValue: false
    }
  });

export default i18nSetup;