import { v4 as uuid } from 'uuid';
import { Container, Title, Panel, Card, CardHeader, CardTitle, CardBody, CardPicture, CardImage, CardContent, Paragraph } from './styles';
import { useTranslation } from 'react-i18next';
import VZeros from '../../images/5zeros.jpeg';
import Objetiva from '../../images/objetiva.png';
import Zenvia from '../../images/zenvia.png';

export const Experiences = ((): JSX.Element => {
  const { t } = useTranslation();
  const history: ExperienceHistory[] = [
    { title: '5Zeros', image: VZeros, link: '5zeros', role: t('exp_5zs_role'), period: t('exp_5zs_period') },
    { title: 'Objetiva Soluções', image: Objetiva, link: 'objetiva-solucoes-em-ti', role: t('exp_obj_role'), period: t('exp_obj_period') },
    { title: 'Zenvia', image: Zenvia, link: 'zenvia-inc', role: t('exp_zen_role'), period: t('exp_zen_period') }
  ];

  const openLink = ((link: string) => window.open(`https://www.linkedin.com/company/${link}`, '_blank')?.focus());

  return (
    <Container>
      <Title>{t('exp_title')}</Title>
      <Panel>
        {
          history.map(({ title, image, link, role, period }) => {
            return (
              <Card key={uuid()} onClick={((_) => openLink(link))}>
                <CardHeader>
                  <CardTitle>{title}</CardTitle>
                </CardHeader>
                <CardBody>
                  <CardPicture>
                    <CardImage src={image} width={'100px'} />
                  </CardPicture>
                  <CardContent>
                    <Paragraph>{role}</Paragraph>
                    <Paragraph color={'gray2'}>{period}</Paragraph>
                  </CardContent>
                </CardBody>
              </Card>
            );
          })
        }
      </Panel>
    </Container>
  );
});