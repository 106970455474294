import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { SiTypescript, SiC, SiCplusplus, SiNestjs } from 'react-icons/si';
import { DiDjango, DiPostgresql, DiMysql, DiMongodb } from 'react-icons/di';
import { FaJs, FaPhp, FaRust, FaJava, FaNodeJs, FaPython, FaAngular, FaReact, FaVuejs, FaAws, FaGit, FaDocker, FaLaravel } from 'react-icons/fa';
import { Container, Header, Title, Select, Option, Board } from './styles';
import { Badge, Text } from '../';

export const Technologies = ((): JSX.Element => {
  const { t } = useTranslation();
  const TechsList: TechItem[] = [
    { text: 'Angular', level: 'low', IconTag: FaAngular },
    { text: 'AWS', level: 'medium', IconTag: FaAws },
    { text: 'C', level: 'medium', IconTag: SiC },
    { text: 'C++', level: 'low', IconTag: SiCplusplus },
    { text: 'Django', level: 'high', IconTag: DiDjango },
    { text: 'Docker', level: 'high', IconTag: FaDocker },
    { text: 'Git', level: 'high', IconTag: FaGit },
    { text: 'Java', level: 'low', IconTag: FaJava },
    { text: 'JavaScript', level: 'high', IconTag: FaJs },
    { text: 'Laravel', level: 'low', IconTag: FaLaravel },
    { text: 'MongoDB', level: 'low', IconTag: DiMongodb },
    { text: 'MySQL', level: 'high', IconTag: DiMysql },
    { text: 'NestJS', level: 'high', IconTag: SiNestjs },
    { text: 'NodeJS', level: 'high', IconTag: FaNodeJs },
    { text: 'PHP', level: 'medium', IconTag: FaPhp },
    { text: 'PostgreSQL', level: 'high', IconTag: DiPostgresql },
    { text: 'Python', level: 'high', IconTag: FaPython },
    { text: 'React', level: 'medium', IconTag: FaReact },
    { text: 'Rust', level: 'low', IconTag: FaRust },
    { text: 'TypeScript', level: 'high', IconTag: SiTypescript },
    { text: 'Vue', level: 'medium', IconTag: FaVuejs }
  ];
  const [techs, setTechs] = useState<TechItem[]>(TechsList);

  const GetColorByLevel = ((level: TechBadgeLevel) => {
    const ColorMap = new Map([
      ['low', 'jet'],
      ['medium', 'umber'],
      ['high', 'ultra-violet']
    ]);

    return ColorMap.get(level) ?? 'jet';
  });

  const handleOnChange = ((e: React.ChangeEvent<HTMLSelectElement>) => {
    const { target: { value } } = e;

    if (value === 'all') {
      setTechs([...TechsList]);
      return;
    }

    const techsFiltered = TechsList.filter(({ level }) => level === value);

    setTechs([...techsFiltered])
  });

  return (
    <Container>
      <Header>
        <Title>{t('tech_title')}</Title>

        <Select onChange={handleOnChange} placeholder={t('tech_level')} defaultValue={'all'}>
          <Option disabled hidden value='all'>{t('tech_level')}</Option>
          <Option value='low'>{t('tech_beginner')}</Option>
          <Option value='medium'>{t('tech_intermediate')}</Option>
          <Option value='high'>{t('tech_advanced')}</Option>
          <Option value='all'>{t('tech_all')}</Option>
        </Select>
      </Header>

      <Board>
        {
          techs.map(({ text, level, IconTag }) => {
            return (
              <Badge key={uuid()} $background={GetColorByLevel(level)}>
                <IconTag />
                <Text>{text}</Text>
              </Badge>
            );
          })
        }
      </Board>
    </Container>
  );
});