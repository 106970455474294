import { Container, Panel, Title, Box, BoxHeader, BoxTitle, BoxBody, Description, IconsArea, Icon, BoxContent, BoxLink } from './styles';
import { FaDocker, FaAws, FaReact, FaCss3, FaNodeJs } from 'react-icons/fa';
import { SiHandlebarsdotjs, SiRabbitmq } from 'react-icons/si';
import { SiNestjs, SiTypescript } from 'react-icons/si';
import { useTranslation } from 'react-i18next';
import { DiPostgresql } from 'react-icons/di';
import { v4 as uuid } from 'uuid';
import { useRef } from 'react';

export const Projects = (() => {
  const { t } = useTranslation();
  const mountLink = ((repositoryName: string) => `https://github.com/FernasG/${repositoryName}`);
  const repositories = [
    { title: 'code-editor-nestjs', icons: [FaDocker, SiNestjs, DiPostgresql, FaAws], description: t('proj_editor_nestjs_desc') },
    { title: 'code-editor-nextjs', icons: [FaDocker, FaReact, FaCss3], description: t('proj_editor_nextjs_desc') },
    { title: 'COM221-dealership', icons: [FaDocker, FaNodeJs, SiTypescript, SiHandlebarsdotjs], description: t('proj_dealership_desc') },
    { title: 'COM242-bz-gateway', icons: [FaDocker, SiNestjs, DiPostgresql, SiRabbitmq], description: t('proj_bz_gateway_desc') },
    { title: 'COM242-bz-ms-system', icons: [FaDocker, SiNestjs, DiPostgresql], description: t('proj_bz_system_desc') }
  ];

  const sliderRef = useRef<HTMLDivElement>(null);
  let isDown = false;
  let startX: number | undefined;
  let scrollLeft: number | undefined;

  const calcPointX = ((e: React.MouseEvent | React.TouchEvent): number => {
    const pageX = Object.keys(e).includes('touches') ?
      (e as React.TouchEvent).touches[0].pageX :
      (e as React.MouseEvent).pageX;

    const sliderX = sliderRef.current ? sliderRef.current.offsetLeft : 0;

    return pageX - sliderX;
  });

  const end = (() => {
    isDown = false;

    if (!sliderRef.current) return;

    sliderRef.current.classList.remove('active');
  });

  const start = ((e: React.MouseEvent | React.TouchEvent) => {
    isDown = true;

    if (!sliderRef.current) return;

    sliderRef.current.classList.add('active');
    startX = calcPointX(e);
    scrollLeft = sliderRef.current.scrollLeft || 0;
  });

  const move = ((e: React.MouseEvent | React.TouchEvent) => {
    if (!isDown) return;

    if (!sliderRef.current) return;

    if (startX === undefined || scrollLeft === undefined) return;

    const pointX = calcPointX(e);
    const distination = (pointX - startX);
    sliderRef.current.scrollLeft = (scrollLeft - distination) || 0;
  });

  return (
    <Container>
      <Title>{t('proj_title')}</Title>

      <Panel
        ref={sliderRef}
        onMouseDown={start}
        onTouchStart={start}
        onMouseMove={move}
        onTouchMove={move}
        onMouseLeave={end}
        onTouchEnd={end}
        onMouseUp={end}
      >
        {
          repositories.map(({ title, icons, description }) => {
            return (
              <Box key={uuid()}>
                <BoxContent>
                  <BoxHeader>
                    <BoxLink href={mountLink(title)} target={'_blank'}>
                      <BoxTitle>{title}</BoxTitle>
                    </BoxLink>
                  </BoxHeader>
                  <BoxBody>
                    <IconsArea>
                      {icons.map((IconTag) => <Icon key={uuid()}><IconTag /></Icon>)}
                    </IconsArea>
                    <Description>{description}</Description>
                  </BoxBody>
                </BoxContent>
              </Box>
            );
          })
        }
      </Panel>
    </Container>
  );
});