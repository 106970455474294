import { Container, Title, Paragraph } from './styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const About = ((): JSX.Element => {
  const [age, setAge] = useState<number>(0);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const diffYears = moment().diff('2000-08-25', 'years');
    setAge(diffYears);
  }, [age]);

  return (
    <Container>
      <Title>{t('about_title')}</Title>
      <Paragraph>
        {i18n.t('about_paragraph', { age })}
      </Paragraph>
    </Container>
  );
})