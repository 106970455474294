import { Container, Header, LanguageArea, Image, Title, Headline1, Headline2, Socials, Link } from './styles';
import { FaInstagram, FaGithub, FaLinkedin } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from '../';
import { useState } from 'react';
import Picture from '../../images/picture.jpg';
import PigeonMeme from '../../images/pigeon_meme.png';

export const Profile = ((): JSX.Element => {
  const { t } = useTranslation();
  const [src, setSrc] = useState<string>(Picture);
  const onClickHandler = (() => {
    const newSrc = src === PigeonMeme ? Picture : PigeonMeme;
    setSrc(newSrc);
  });

  return (
    <Container>
      <Header>
        <LanguageArea>
          <LanguageSwitcher />
        </LanguageArea>
        <Image onClick={onClickHandler} src={src} width={'225px'} height={'225px'} />
      </Header>

      <Title>
        <Headline1>Fernando Goulart</Headline1>
        <Headline2>{t('profile_web_dev')}</Headline2>
      </Title>

      <Socials>
        <Link target={'_blank'} href={'https://www.instagram.com/fernando.pgoulart'}>
          <FaInstagram size={'30'} />
        </Link>
        <Link target={'_blank'} href={'https://github.com/FernasG'}>
          <FaGithub size={'30'} />
        </Link>
        <Link target={'_blank'} href={'https://www.linkedin.com/in/fernando-p-goulart/'}>
          <FaLinkedin size={'30'} />
        </Link>
      </Socials>
    </Container>
  );
});