import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--layer-00);
`;

export const Text = styled.p`
  font-size: 0.8em;
  color: var(--gray2);
`;

export const Link = styled.a`
  font-weight: bold;
  transition: all 500ms;

  &:hover {
    color: var(--gray1);
    text-decoration: underline;
  }
`;