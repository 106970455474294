import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled.h3`
  color: var(--vermilion);
`;

export const Select = styled.select`
  color: var(--gray2);
  font-weight: bold;
  font-size: 1em;
  padding: 0 10px;
  border: 2px solid var(--vermilion);
  width: 130px;
  text-align: center;
  border-radius: 50px;
  transition: all 500ms;

  &:hover {
    color: var(--gray1);
  }
`;

export const Option = styled.option`
  background: var(--layer);
`;

export const Board = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 10px;
  row-gap: 10px;
`;