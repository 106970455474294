import { Profile, About, Experiences, Footer, Technologies, Projects } from './components';
import './App.css';

function App() {
  return (
    <>
      <div className="App">
        <Profile />
        <About />
        <Experiences />
        <Technologies />
        <Projects />
      </div>
      <Footer />
    </>
  );
}

export default App;
