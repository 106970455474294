import { ReactNode } from 'react';
import styled from 'styled-components';

export interface BadgeProps extends Props {
  children: ReactNode;
}

interface Props {
  $background: string;
}

export const Container = styled.div<Props>`
  background: ${({ $background }) => `var(--${$background})`};
  border-radius: 20px;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
`;

export const Text = styled.p`
  font-weight: bold;
`;