import { useEffect, useState } from 'react';
import { Dropdown, Button, Content, Item, Text, Flag } from './styles';
import { useTranslation } from 'react-i18next';

export const LanguageSwitcher = ((): JSX.Element => {
  const [language, setLanguage] = useState<string>(navigator.language);
  const { t, i18n } = useTranslation();

  const handleOnClick = ((lng: string) => {
    setLanguage(lng);
    i18n.changeLanguage(lng);
  });

  const createHTML = ((lng: string, flag: string) => {
    return (
      <>
        <Text>{lng}</Text>
        <Flag>{flag}</Flag>
      </>
    );
  })

  const LanguageMap = new Map([
    ['pt-BR', createHTML(t('portuguese'), '🇧🇷')],
    ['en-US', createHTML(t('english'), '🇺🇸')],
    ['es', createHTML(t('spanish'), '🇪🇸')],
    ['en', createHTML(t('english'), '🇺🇸')]
  ]);

  useEffect(() => { }, [language]);

  return (
    <Dropdown>
      <Button>{LanguageMap.get(language)}</Button>
      <Content>
        <Item onClick={(_) => handleOnClick('pt-BR')}>
          <Text>{t('portuguese')}</Text>
          <Flag>🇧🇷</Flag>
        </Item>
        <Item onClick={(_) => handleOnClick('es')}>
          <Text>{t('spanish')}</Text>
          <Flag>🇪🇸</Flag>
        </Item>
        <Item onClick={(_) => handleOnClick('en')}>
          <Text>{t('english')}</Text>
          <Flag>🇺🇸</Flag>
        </Item>
      </Content>
    </Dropdown>
  );
});