import styled from 'styled-components';

export const Content = styled.div`
  display: none;
  position: absolute;
  background: var(--layer);
  border-radius: 10px;
  padding-top: 5px;
  font-size: 0.8em;
  z-index: 1;
  width: 140px;

  &:hover {
    display: block;
  }
`;

export const Button = styled.button`
  background: var(--medium-slate-blue);
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  display: flex;
  color: var(--gray1);
  border-radius: 50px;
  font-weight: bold;
  font-size: 1em;
  padding: 5px 15px;
  cursor: pointer;
  border: none;
  width: 140px;
`;

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  width: 140px;

  &:hover ${Button}:hover + ${Content} {
    display: block;
  }
`;

export const Item = styled.div`
  color: var(--gray1);
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:hover {
    background: var(--medium-slate-blue);
    cursor: pointer;
    color: var(--white);
  }

  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const Text = styled.p``;

export const Flag = styled.span``;